var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items":_vm.displayApplicants,"headers":_vm.headers,"loading":_vm.loading,"page":_vm.page,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.rowClick,"sort":_vm.updateSortBy},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone || item.homePhone)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startDate ? _vm.getDate(item.startDate) : "")+" ")]}},{key:"item.homeVisitDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.homeVisitDate ? _vm.getDate(item.homeVisitDate) : "")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? _vm.transformStatus(item) : "")+" ")]}},{key:"item.deleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted ? _vm.getDate(item.deleted) : "")+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }