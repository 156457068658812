











































import {
  defineComponent,
  ref,
  computed,
  watchEffect,
} from "@vue/composition-api";
import moment from "moment";
import { Applicant } from "@/store";
import { APPLICANT_STATUS_LIST } from "../../store/constants";

interface Props {
  applicants: Array<Applicant>;
  loading: boolean;
  total: number;
  showDeleted?: boolean;
}

export interface Pagination {
  page: number;
  itemsPerPage: number;
  pageStart: number;
  pageStop: number;
  pageCount: number;
  itemsLenth: number;
}

interface Header {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "ApplicantsTable",
  props: {
    applicants: Array,
    loading: Boolean,
    total: Number,
    showDeleted: Boolean,
  },
  setup(props: Props, { emit }) {
    const sortBy = ref<string>("");
    const sortDesc = ref<boolean>();
    const headers = computed<Header[]>(() => {
      const heads = [
        { text: "First Name", value: "firstName", sortable: true },
        { text: "Last Name", value: "lastName", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Phone", value: "phone", sortable: true },
        { text: "Start Date", value: "startDate", sortable: true },
        { text: "Home Vist Date", value: "homeVisitDate", sortable: true },
        { text: "Status", value: "status", sortable: true },
      ];
      if (props.showDeleted) {
        heads.push({ text: "Deleted", value: "deleted", sortable: true });
      }
      return heads;
    });

    const displayApplicants = computed<Applicant[]>(() => {
      return !props.showDeleted
        ? props.applicants.filter((applicant) => applicant.deleted === null)
        : props.applicants;
    });

    const page = ref<number>(1);
    const itemsPerPage = ref<number>(10);
    const pageCount = computed<number>(() =>
      Math.ceil(props.total / itemsPerPage.value)
    );
    const search = ref<string>("");

    //TODO we should probably look to make this a helper function for the app, not just applicant
    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    function rowClick($event: Applicant) {
      emit("rowClick", $event);
    }

    watchEffect(() => {
      if (props.showDeleted) {
        sortBy.value = "deleted";
        sortDesc.value = true;
      } else {
        sortBy.value = "";
        sortDesc.value = false;
      }
    });

    function updateSortBy(sort: string) {
      if (sortBy.value !== sort) {
        sortBy.value = sort;
      } else {
        sortDesc.value = !sortDesc.value;
      }
    }

    function transformStatus(item: Applicant) {
      const status = APPLICANT_STATUS_LIST.find(
        ({ value }) => value === (item || {}).status
      );
      return (status || {}).text;
    }

    return {
      headers,
      page,
      pageCount,
      itemsPerPage,
      search,
      getDate,
      rowClick,
      sortBy,
      sortDesc,
      updateSortBy,
      displayApplicants,
      transformStatus,
    };
  },
});
