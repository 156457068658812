

























import {
  defineComponent,
  computed,
  onBeforeMount,
  ref,
} from "@vue/composition-api";
import {
  useStore,
  useApplicantService,
  useRouter,
} from "@/composables/providers";
import { Applicant } from "@/store";
import ApplicantTable from "@/components/tables/Applicants.vue";
import { ApplicantGetters } from "@/store/applicant";
import { ApplicantActions } from "@/store/applicant/index";

export default defineComponent({
  name: "ApplicantsList",
  components: {
    ApplicantTable,
  },
  setup() {
    const store = useStore();
    const service = useApplicantService();
    const router = useRouter();
    const showDeleted = ref<boolean>(false);
    const applicants = computed<Applicant[]>(
      () => store.getters[ApplicantGetters.APPLICANTS]
    );
    const loading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const total = computed<number>(() => store.getters[ApplicantGetters.TOTAL]);

    onBeforeMount(async () => {
      await store.dispatch(ApplicantActions.LIST, { service });
    });

    async function navigateToApplicant($event: Applicant) {
      await store.dispatch(ApplicantActions.SELECT, {
        id: $event.id,
        service,
      });
      router.push({
        name: "ApplicantDetail",
        params: { id: $event.id.toString() },
      });
    }

    return {
      applicants,
      loading,
      total,
      router,
      navigateToApplicant,
      showDeleted,
    };
  },
});
